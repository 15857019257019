<template>
  <div>
    <ClaimSuccessLoading v-if="isLoading" />
    <StatusPage
      v-else
      class="home-repair-service-success-page"
      status="success"
      restrict-title-width
    >
      <template #title>
        <p>Good news! You've successfully set up your Service Call for Home Service</p>
      </template>
      <template>
        <p class="home-repair-service-success-page__text">
          A technician will call you to diagnose and troubleshoot the problem over the phone. If the
          problem can be solved over the phone, there will be no need for a subsequent in-home
          visit.
        </p>
        <!-- TODO! -->
        <!-- <HomeServiceDateAndTimeBlock
          class="home-repair-service-success-page__date-and-time"
          title="Preferred date and time of the call:"
          :formatted="formatted"
        /> -->
        <GoToMyDevicesButton class="home-repair-service-success-page__action-button" />
      </template>
    </StatusPage>
  </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex';
import StatusPage from '@/components/shared/StatusPage';
import GoToMyDevicesButton from '@/components/shared/GoToMyDevicesButton';
// import HomeServiceDateAndTimeBlock from '@/components/claim/components/home-service/HomeServiceDateAndTimeBlock';
import ClaimSuccessLoading from '@/components/shared/ClaimSuccessLoading';
import {allowDebug} from '@/constants/env';

export default {
  components: {
    StatusPage,
    // HomeServiceDateAndTimeBlock,
    GoToMyDevicesButton,
    ClaimSuccessLoading,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters('jobInfo', ['getByMasterId']),
    claimId() {
      return this.$route.params.id;
    },
    claim() {
      return this.getByMasterId(this.claimId);
    },
  },
  watch: {
    claimId: {
      async handler() {
        const {customerRef, contractRef} = await this.DECRYPT_PARAMS({
          customerRef: this.$route.query.customerRef,
          contractRef: this.$route.query.contractRef,
        });

        await this.GET_CLAIMS_LIST({customerRef});
        await this.$nextTick();

        const claim = this.claim;
        const claimDetails = this.claim?.claimDetails;
        const deviceId = claimDetails?.inventoryEquipmentID;
        const triageReferenceId = claimDetails?.triageReferenceID;
        const productSubCategoryCode = claim?.productLine;
        const issueCode = claim?.complaintCode;
        const issueRemarks = claim?.complainCodeName;
        const issueCategoryCode = claim?.issueCategoryCode;
        const serviceLocationCode = claim?.serviceProviderAccount;

        const _addressType = claim?.customerAddressType;
        const _city = claim?.customerCity;
        const _flatNo = claim?.customerFlatNo;
        const _houseNo = claim?.customerHouseNo;
        const _landmark = claim?.customerLandmark;
        const _state = claim?.customerState;
        const _address = claim?.customerClaimAddressLine1;
        const _street = claim?.customerStreetName;
        const _zipCode = claim?.customerPostalCode;

        const geoLocation = await this.GET_LOCATION_BY_ADDRESS({
          city: _city,
          flatNo: _flatNo,
          houseNo: _houseNo,
          landmark: _landmark,
          state: _state,
          address: _address,
          streetName: _street,
          zipcode: _zipCode,
        });

        // TODO: Lost date should be less of equal to current date to be valid.
        // That might cause issues when client-side time is different from server-side timezone.
        const lossDateTime = claimDetails?.lossDateTime?.split('T')[0];
        const descriptionOfLoss = claimDetails?.descriptionOfLoss;
        const externalReferenceID = this.$route.params.id;

        const scheduledServiceDate = claim?.scheduledServiceDate;
        const scheduledFromTime = claimDetails?.scheduledFromTime;
        const scheduledToTime = claimDetails?.scheduledToTime;

        const servifyClaim = await this.START_SERVIFY_CLAIM({
          serviceTypeCode: 'CLAIM_ONSITE',
          deviceId,
          productSubCategoryCode,
          customerRef,
          contractRef,
          externalReferenceID,
          issueCode,
          issueRemarks,
          issueCategoryCode,
          lossDateTime,
          descriptionOfLoss,

          consumerServiceRequestParams: {
            externalReferenceID,
            triageReferenceId,
            serviceLocationCode,

            scheduledServiceDate,
            scheduledFromTime,
            scheduledToTime,

            addressType: _addressType,
            city: _city,
            flatNo: _flatNo,
            houseNo: _houseNo,
            landmark: _landmark,
            state: _state,
            streetName: _street,
            StreetName: _street,
            address: _address,
            zipcode: _zipCode,

            lat: geoLocation?.latitude,
            lng: geoLocation?.longitude,
            latitude: geoLocation?.latitude,
            longitude: geoLocation?.longitude,
          },
        });

        if (servifyClaim?.errorDetails?.errorDetail?.errorDescription) {
          this.showClaimCreationError();
          if (allowDebug) {
            this.$toasted.show(servifyClaim.errorDetails.errorDetail.errorDescription);
          }
        }

        this.isLoading = false;
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('user', ['DECRYPT_PARAMS']),
    ...mapActions('jobInfo', ['GET_CLAIMS_LIST']),
    ...mapActions('requestService', ['START_SERVIFY_CLAIM', 'GET_LOCATION_BY_ADDRESS']),
    showClaimCreationError() {
      const {customerRef, contractRef} = this.$route.query;
      const query = {customerRef, contractRef};
      const devicesLink = {
        name: 'MyDevices',
        query,
      };
      this.$store.dispatch('setModalError', {
        text: [
          'Seems like we have a trouble processing your request.',
          'Please contact the call center.',
        ],
        cta: [
          {
            text: 'Go to My Devices',
            callback: () => {
              this.$store.commit('errorModal/CLEAR_ERROR_MODAL_DATA');
              this.$router.push(devicesLink);
            },
          },
        ],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.home-repair-service-success-page {
  &__text {
    max-width: 330px;
    margin: 16px 0;
    color: $darkGray;
  }

  &__action-button {
    margin-top: 35px;
  }

  &__date-and-time {
    margin: auto;
  }
}
</style>
